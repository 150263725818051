import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  DatatableWrapper,
  Pagination,
  TableBody,
  TableHeader
} from 'react-bs-datatable';  
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import Confirm from './Confirm';
import FilesEdit from './FilesEdit';  
import FilesView from './FilesView';
import fileDownload from 'js-file-download';
import Image from 'react-bootstrap/Image'
import * as constants from '../constants/CONSTANT';

const RelatedListFiles = (props) => {
  
  const [modalShow, setModalShow] = React.useState(false);
  const [file, setFile] = React.useState('');
  const [downloads, setDownloads] = React.useState([]);
  const [modalShowFile, setModalShowFile] = React.useState(false);
  const [modalShowFileView, setModalShowFileView] = React.useState(false);
  
  const [body, setBody] = useState([]);
  const MIMEType = new Map([
    ["text/csv", "csv"],
    ["application/msword", "doc"],
    ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "docx"],
    ["image/gif", "gif"],
    ["text/html", "html"],
    ["image/jpeg", "jpeg"],
    ["image/jpg", "jpg"],
    ["application/json", "json"],
    ["audio/mpeg", "mp3"],
    ["video/mp4", "mp4"],
    ["image/png", "png"],
    ["application/pdf", "pdf"],
    ["application/vnd.ms-powerpoint", "ppt"],
    ["application/vnd.openxmlformats-officedocument.presentationml.presentation", "pptx"],
    ["image/svg+xml", "svg"],
    ["text/plain", "txt"],
    ["application/vnd.ms-excel", "xls"],
    ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx"],
    ["text/xm", "xml"],
    ["application/xml", "xml"],
    ["application/atom+xml", "xml"],
    ["application/zip", "zip"],
    ]);

  useEffect(() => {
    filesList();
    
  }, [props.refreshFileList]);

  const handleDelete = (row) => {
    setModalShow(true);
    setFile(row);
  }

  

  const labels = {
    beforeSelect: " "
  }

  const deleteFile = async () => {
    ////console.log('delete call', file.id)
    const result = await SparkApi.deleteFile(file.id);
    //console.log('delete successfully ',result)
    if (result.success) {
        const filterFiles = body.filter((item) => item.id !== file.id);
        setBody(filterFiles);
        setModalShow(false);
    }
  }

  const downloadFile = async (row) => {
    //console.log('if file download call',row);
    const result = await SparkApi.downloadFiles(row.id);
    if(result){
      fileDownload(result, row.title);
    }
  
   /* if (result.success) {
        //////console.log('download success')
        setFile('');
        //setModalShow(false);
        //filesList();
    }*/
  }

 

  const submitFiles = () => {
    setModalShowFile(false);
    filesList();
  }

  const editFile = (row) => {
    setModalShowFile(true)
    setFile(row);
  }

  const fileView = (row) => {
    setModalShowFileView(true)
    setFile(row);
  }

  const filesList = () => {
    async function init() {
      //console.log('variable:', props.parent);
      let files = await SparkApi.fetchFiles(props.parent.id);
      //console.log('FILES',files)
      if (files && files?.length > 0){
        let arr = [];
       
        for(let i=0; i < files.length ; i++){
          let file = files[i];
          if(file.filetype === 'jpg'
          || file.filetype === 'jpeg'
          || file.filetype === 'png'){

            //////console.log('fetch:', file.filetype);
            let result = await SparkApi.downloadFiles(file.id);
            //////console.log('result:', result);
            file['body'] = window.URL.createObjectURL(result);
        }else{
          file['body'] = '';
        }

          
          arr.push(file);
        }
   
        setBody([...arr]);

      
      }else{
        setBody([]);
      }
    }
    init();
  }



  const fileSize = (bytes) =>  {
    var exp = (bytes / 1024)  / 1024;
    return exp.toFixed(2)+" MB";
  }

  const renderPreview = (type, body) => {
    //////console.log(type);
    switch(type) {
      case constants.PDF:
        return <i className='fa-solid fa-file-pdf fa-2xl file-thumbnail'></i>;
      case constants.DOC:
      case constants.DOCX:
        return <i className='fa-solid fa-file-word fa-2xl file-thumbnail'></i>;
      case constants.XLS:
      case constants.XLSX:
         return <i className='fa-solid fa-file-excel fa-2xl file-thumbnail'></i>;
      case constants.PNG:
      case constants.JPG:
      case constants.JPEG:
         return <Image src={body} thumbnail width="200px"/>
      default:
        return <i className='fa-solid fa-file-file fa-2xl file-thumbnail'></i>;
    }
  }

  const header = [
    {
      title: 'Title', prop: 'title',
      cell: (row) => (
        <>
        <Link
        onClick={() => fileView( {row} )}
          state={props.parent}
        >
          {row.title}
        </Link>

        
                </>
      )
    },
    { title: '', prop: 'body',cell: (row) => (
      <>
    
      <center><a href='javascript:void(0)' onClick={() => downloadFile(row)}>{renderPreview(row.filetype, row.body)}</a></center>
     
      
      </>

    ) },
    { title: 'File Type', prop: 'filetype',cell: (row) => row.filetype },
    { title: 'File Size', prop: 'filesize',cell: (row) => (fileSize(row.filesize))},
    { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')) },
    
    {
      title: 'Actions', prop: 'id', cell: (row) => (
        <><Button className='btn-sm mx-2'  onClick={() => editFile({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
          <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)} ><i className="fa-regular fa-trash-can"></i></Button>
          <Button className='btn-sm mx-2' variant='danger' onClick={() => downloadFile(row)} ><i class="fa-solid fa-download"></i></Button>
          
        </>
      )
    }
  ];


  return (
    <>
      {modalShow &&
      <Confirm
        show={modalShow}
        onHide={() => setModalShow(false)}
        deleteFile={deleteFile}
        title="Confirm delete?"
        message="You are going to delete the record. Are you sure?"
        table="file"
      />}
     {modalShowFile &&
        <FilesEdit
          show={modalShowFile}
          onHide={() => setModalShowFile(false)}
          parent={props.parent}
          file={file}
          table="lead"
          submitFiles={submitFiles}
        />
        }
        {modalShowFileView &&
        <FilesView
          show={modalShowFileView}
          onHide={() => setModalShowFileView(false)}
          file={file}
        />
        }
      {body ?



        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
          initialState: {
            rowsPerPage: 5
          }
        }}>
          <Row className="mb-4">
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
            </Col>
            
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-start align-items-start"
            >
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >
            </Col>
          </Row>
          <Table striped className="related-list-table">
            <TableHeader />
            <TableBody />
          </Table>
          <Pagination />
        </DatatableWrapper> : ''}
        <p style={{color:"red"}}>
        {downloads?.length > 0 ?  downloads.map((row, index) => ( 
            
            <img src={row} />
            
        )) : ''}  
        </p>
    </>

    
  )
}

export default RelatedListFiles
