/* Start updated by Pooja Vaishnav and it will show particular product related pricebook 
  and this component call in productView*/
  
  import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
  import { Button, Col, Row, Table } from 'react-bootstrap';
  import { Link } from 'react-router-dom';
  import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
  } from 'react-bs-datatable';
  import SparkApi from "../api/SparkApi";
  import moment from 'moment';
  import Confirm from './Confirm';
  import PricebookEdit from './PricebookEdit';
  
  const RelatedListPricebook = ({ parent, refreshPricebookList }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [pricebook, setPricebook] = React.useState('');
    const [modalShowPricebook, setModalShowPricebook] = React.useState(false);
    const [modalShowPricebookView, setModalShowPricebookView] = React.useState(false);
  
    const [body, setBody] = useState([]);
    useEffect(() => {
      pricebookList();
    }, []);
  
    useEffect(() => {
      pricebookList();
  
    }, [refreshPricebookList]);
  
    const pricebookList = () => {
      async function init() {
        let pricebooks = await SparkApi.fetchPricebookByProductId(parent.id);
        if (pricebooks && pricebooks?.length > 0) {
          setBody(pricebooks);
        } else {
          setBody([]);
        }
      }
      init();
    }
    const handleDelete = (row) => {
      setModalShow(true);
      setPricebook(row);
    }
  
    const labels = {
      beforeSelect: " "
    }
  
    const deletePricebook = async () => {
      const result = await SparkApi.deletePricebook(pricebook.id);
      if (result.success) {
        setPricebook('');
        setModalShow(false);
        pricebookList();
      }
    }
  
    const submitPricebooks = () => {
      setModalShowPricebook(false);
      pricebookList();
    }
  
    const editPricebook = (row) => {
      setModalShowPricebook(true)
      setPricebook(row.row);
    }
  
    const viewPricebook = (row) => {
      setModalShowPricebookView(true)
      setPricebook(row);
    }
  
    const header = [
      { title: 'Amount', prop: 'amount', isSortable: true },
      { title: 'Gst %', prop: 'gst', isSortable: false },
      { title: 'Active', prop: 'active', cell: (row) => (<input type="checkbox" className="test" checked={row.active} />) },
      { title: 'Created Date', prop: 'createddate', cell: (row) => (moment(row.createddate).format('DD-MM-YYYY')), isSortable: true },
      { title: 'Created By', prop: 'createdbyname' },
      {
        title: 'Actions', prop: 'id', cell: (row) => (
          <>
            <Button className='btn-sm mx-2' onClick={() => editPricebook({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>
            <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i className="fa-regular fa-trash-can"></i></Button>
          </>
        )
      }
    ];
  
    return (
      <>
        {modalShow &&
          <Confirm
            show={modalShow}
            onHide={() => setModalShow(false)}
            deletePricebook={deletePricebook}
            title="Confirm delete?"
            message="You are going to delete the record. Are you sure?"
            table="pricebook"
          />}
        {modalShowPricebook &&
          <PricebookEdit
            show={modalShowPricebook}
            onHide={() => setModalShowPricebook(false)}
            parent={parent}
            pricebooks={pricebook}
            submitPricebooks={submitPricebooks}
          />
        }
  
        {body ?
          <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
            initialState: {
              rowsPerPage: 5
            }
          }}>
            <Row className="mb-4">
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-start align-items-start"
              >
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={4}
                className="d-flex flex-col justify-content-end align-items-end"
              >
              </Col>
            </Row>
            <Table striped className="related-list-table">
              <TableHeader />
              <TableBody />
            </Table>
            <Pagination />
          </DatatableWrapper> : ''}
      </>
    )
  }
  export default RelatedListPricebook
  /* End updated by Pooja Vaishnav */