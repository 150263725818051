import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";
import { isMobile, MobileView, BrowserView } from 'react-device-detect';
import jwt_decode from "jwt-decode";


import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import InfoPill from "./InfoPill";

const LeadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [body, setBody] = useState([]);
  const [leadname, setLeadName] = useState();
  // const[contact,setContact] = useState(location.state ? location.state : {});
  const [lead, setLead] = useState();
  const [userInfo, setUserInfo] = useState({});
  //////console.log('location.state ='+location.state)
  const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem('token')));

    async function init() {
      const leads = await SparkApi.fetchLead();

      ////console.log('leads', leads)
      if (leads) {
        ////console.log("lead data =>", leads);
        setBody(leads);
        setLead(leads);
      } else {
        setBody([]);
        setLead([]);
      }
    }
    init();
    setLeadName(body.firstname + " " + body.lastname);
  }, [leadname]);

  const onFilterType = (event) => {
    if (event.target.value === "") {
      setBody(lead);
    } else {
      setBody(
        lead.filter((data) => {
          if (
            (data.leadstatus || "").toLowerCase() ===
            (event.target.value || "").toLowerCase()
          ) {
            return data;
          }
        })
      );
    }
  };

  const getStatusClass = (status) => {
    ////console.log('status',status)
    let accessStatusRec = leadStatusArray.filter((value, index, array) => {
      if (value.label === status) {

        return true
      }

    });
    ////console.log('accessStatusRec',accessStatusRec)
    if (accessStatusRec && accessStatusRec.length > 0) {

      if (accessStatusRec[0].is_converted === true) {
        ////console.log('if isconverted')

        return 'success';
      } else if (accessStatusRec[0].is_lost === true) {
        return 'secondary';
      } else {
        return 'primary';
      }
    } else {
      return 'secondary';
    }
  }


  // Create table headers consisting of 4 columns.
  const header = [];

  if (!isMobile) {
    header.push(
      {
        title: "Name",
        prop: "leadname",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <Link to={"/leads/" + row.id} state={row}>
            {row.leadname}
          </Link>
        ),
      },
      { title: "Phone", prop: "phone", isFilterable: true },
      { title: "Email", prop: "email", isFilterable: true },

      {
        title: "Lead Status",
        prop: "leadstatus",
        isFilterable: true, isSortable: true,
        cell: (row) => {
          return (
            <Badge bg={getStatusClass(row.leadstatus)} style={{ display: "block", paddingBottom: "5px" }}>
              {row.leadstatus}
            </Badge>
          );

        },
      },
      { title: "Company", prop: "company", isFilterable: true, isSortable: true },
      { title: "Lead Source", prop: "leadsource", isFilterable: true },
      {
        title: "Created Date", prop: "createddate", isSortable: true, isFilterable: true, cell: (row) => (
          <span>
            {moment(row.createddate).format('DD MMM, yyyy')}
          </span>
        )
      },
      {
        title: "Assigned", prop: "ownername", isFilterable: true, isSortable: true, cell: (row) => (
          <Link to={"/users/" + row.ownerid} state={row} className="align-items-center justify-content-start">
            <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${row.ownerid}.thumbnail/${Math.random()}`} className="rounded-circle" style={{ height: "30px", width: "30px", objectFit: "cover" }} />
            <span className="mx-2">{row.ownername}</span>
          </Link>
        ),
      },
    )
  } else {
    //for mobile device

    header.push(
      {
        title: "Info",
        prop: "leadname",
        isFilterable: true, isSortable: true,
        cell: (row) => (
          <div className="mobilecard">
            <Link to={"/leads/" + row.id} state={row} style={{ fontSize: "1.2rem" }}>
              {row.leadname}
            </Link>
            <Link to={"/users/" + row.ownerid} state={row}>
              <i class="fa-solid fa-user"></i> {row.ownername}
            </Link>
            <span><i class="fa-solid fa-phone"></i> {row.phone}</span>
            <span style={{ width: "80%" }}><i class="fa-solid fa-envelope"></i> {row.email}</span>
            <Badge bg={getStatusClass(row.leadstatus)} style={{ paddingBottom: "5px", width: "80%" }}>
              {row.leadstatus}
            </Badge>
          </div>
        ),
      },
    )
  }





  // Randomize data of the table columns.
  // Note that the fields are all using the `prop` field of the headers.
  const labels = {
    beforeSelect: " ",
  };

  const createLead = () => {
    navigate(`/leads/e`);
  };

  return (
    <Row className="g-0">

      <Col lg={12} className="px-4">
        <DatatableWrapper body={body} headers={header} paginationOptionsProps={{ initialState: { rowsPerPage: 15, options: [5, 10, 15, 20], }, }} sortProps={{ initialState: { prop: "createddate", order: "desc" } }}>
          <Row className="mb-4">
            <Col
              xs={12}
              lg={4}
              className="d-flex flex-col justify-content-end align-items-end"
            >

              <Filter />
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={5}
              className="d-flex flex-col justify-content-start align-items-center"
            >
              <PaginationOptions labels={labels} />
              <Form.Group className="mx-3 mt-4" controlId="formBasicStatus">
                <Form.Select
                  aria-label="Enter status"
                  name="leadstatus"
                  onChange={onFilterType}
                >
                  <option value="">--Select Type--</option>
                  {leadStatusArray.map((item, index) => (
                    <option value={item.label} key={index}>
                      {item.label}
                    </option>
                  ))}



                </Form.Select>

              </Form.Group>
              <BrowserView>
                <InfoPill left="Total" right={body?.length} />
              </BrowserView>
            </Col>
            <Col
              xs={12}
              sm={6}
              lg={3}
              className="d-flex flex-col justify-content-end align-items-end"
            >
              <Button
                className="btn-sm"
                variant="outline-primary mx-2"
                onClick={() => createLead(true)}
              >
                New Lead
              </Button>
              {/* <Button className="btn-sm" variant="outline-primary" onClick={()=>location.state ? navigate(`/contacts/${contact.id}`) : navigate('/contacts/')}>Back to Contact</Button> */}
            </Col>
          </Row>
          {body ? (
            <Table striped className="data-table" responsive="sm">
              <TableHeader />

              <TableBody />
            </Table>
          ) : (
            <ShimmerTable row={10} col={8} />
          )}

          <Pagination />
        </DatatableWrapper>
      </Col>
      <Col lg={2}></Col>
    </Row >
  );
};
export default LeadList;
