import React, { Children, useEffect, useState } from "react";
import { Button, Card, Col, Row, Tab, Tabs, Table, Form, Alert, Container } from "react-bootstrap";
import { Navbar, Nav } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import PubSub from 'pubsub-js';

import {
    DatatableWrapper,
    Filter,
    Pagination,
    TableBody,
    TableHeader,
    PaginationOptions,

} from "react-bs-datatable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShimmerTable } from "react-shimmer-effects";
import SparkApi from "../api/SparkApi";
import Modal from 'react-bootstrap/Modal';
import BarChart from "./charts/BarChart";
import moment from "moment";
import ReportFilter from "./ReportFilter";
import PieChart from "../components/charts/PieChart";
import VerticalChart from "../components/charts/VerticalChart";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn, faChartBar, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const ReportEdit = (props) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("Fields");
    const navigate = useNavigate();
    const [body, setBody] = useState([]);
    const [fieldData, setFieldData] = useState([]);
    const [selectedColumn, setSelectedColumn] = useState([]);
    const [activenavTab, setactivenavTab] = useState("Report");
    const [rows, setRows] = useState([]);
    const [sortedRow, setsortedRow] = useState([]);
    const [showSortBy, setShowSortBy] = useState(false);
    const [state, setstate] = useState();
    const [groupQueryObject, setgroupQueryObject] = useState({});
    const [filterrows, setFilterRows] = useState([]);
    const [datatableShow, setDataTableShow] = useState(false);
    const [filter, setFilter] = useState(false);
    const [commaQuery, setcommaQuery] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [reportnamemodal, setreportnamemodal] = useState(false);
    const handleClose = () => setreportnamemodal(false);
    const [completeData, setcompleteData] = useState({});
    const [groupbyQuery, setGroupbyQuery] = useState({});
    const [integerSummerizedata, setintegerSummerizedata] = useState(false);
    const [groupbyqueryresult, setgroupbyqueryresult] = useState([]);
    const [groupby, setgroupby] = useState('');
    const [reportnamedata, setreportnamedata] = useState({});
    const [dataTableFields, setDatatableFields] = useState();
    const [barchart, setbarchart] = useState(true);
    const [verticalchart, setverticalchart] = useState(false);
    const [piechart, setpiechart] = useState(false);
    const [sortingQuery, setsortingQuery] = useState('');
    const [fieldDatatype, setfieldDatatype] = useState({});
    const [modalValues, setmodalValues] = useState({});
    const [filterPopupShow, setFilterPopupShow] = useState(false);
    const [locationdata, setLocationData] = useState(location.state ? location.state : {});
    //console.log('locationdata', locationdata)
    const [update1, setupdate1] = useState({});
    const [update2, setupdate2] = useState({});
    const [update3, setupdate3] = useState({});
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filterQuery, setfilterQuery] = useState('')

    useEffect(() => {
        handleSelect("Fields")
        accessTableField();
        manageAlldata()
    }, []);

    // Access data according to query
    const manageAlldata = async () => {

        let filterQueryStr = '';

        if (locationdata.id) {
            setbarchart(false)
            if (locationdata.charttype === 'vertical') {
                setverticalchart(true)
            } else if (locationdata.charttype === 'barchart') {
                setbarchart(true)
            } else if (locationdata.charttype === 'piechart') {
                setpiechart(true)
            } else {
                //console.log('blank chart')
            }
            setreportnamedata({ ...reportnamedata, apiname: `Copy_${locationdata.apiname}`, name: `Copy_${locationdata.name}`, discription: locationdata.discription })
            setgroupQueryObject({ ...groupQueryObject, field: locationdata?.groupbyquery?.field, fieldtype: locationdata?.groupbyquery?.fieldtype, groupcolumn: locationdata?.groupbyquery?.groupcolumn, groupcolumntype: locationdata?.groupbyquery?.groupcolumntype, groupdsummraize: locationdata?.groupbyquery?.groupdsummraize, query: locationdata?.groupbyquery?.query })
            setgroupby(locationdata?.groupbyquery?.query)
            setupdate3({ ...update3, groupdsummraize: locationdata?.groupbyquery?.groupdsummraize });
            //console.log('location data', locationdata)
            let result = await SparkApi.fetchReportById(locationdata.id)
            //console.log('result ==>', result)

            // **************************************** DATA TABLE SHOW SPLIT WORK ************************
            let query = locationdata?.query
            let querySpliting = query.split(' where ');
            //console.log('querySpliting', querySpliting)
            setDatatableFields(querySpliting[0])
            let queryResult = await SparkApi.fetchQueryData(query);
            //console.log('queryResult==>', queryResult);
            if (queryResult) {
                const keys = Object.keys(queryResult[0]);
                const comma_separated_values = keys.join(', ');
                //console.log('comma_separated_values:', comma_separated_values);
                setcommaQuery(comma_separated_values);
                const newRows = keys.map((value) => createNewRow(value));
                setRows(newRows);
                queryResult = queryResult.map((value) => {
                    value.createddate = moment(value.createddate).format('YYYY-MM-DD');
                    value.lastmodifieddate = moment(value.createddate).format('YYYY-MM-DD');
                    return value;
                });

                setShowSortBy(true);
                setBody(queryResult);
                setFilter(true);
                setDataTableShow(true);
            } else {
                setBody([])
                setDataTableShow(true);
            }


            // *******************************FILTER CONDITION SPLIT WORK USING QUERY *********************8

            let filtetQuerySplit = querySpliting[1]?.split(' ORDER BY ');
            //console.log('filtetQuerySplit', filtetQuerySplit);
            if (filtetQuerySplit != undefined) {
                let filters = filtetQuerySplit[0];
                //console.log('filters', filters);

                let filterParts = filters.split(' AND ');
                //console.log('filterParts', filterParts);

                const pattern = /(\w+)\s*(!=|==|>|<|>=|<=|=)\s*'([^']+)'/g;
                const objectData = [];

                if (filterParts.length === 1) {
                    //console.log('call');
                    // Single filter condition, directly parse it
                    let match;
                    while ((match = pattern.exec(filterParts[0])) !== null) {
                        //console.log('match', match);
                        const field = match[1];
                        const operator = match[2];
                        const value = match[3];
                        objectData.push({ field, operator, value });
                    }
                } else {
                    // Multiple filter conditions, process each element separately
                    for (let i = 0; i < filterParts.length; i++) {
                        let match;
                        while ((match = pattern.exec(filterParts[i])) !== null) {
                            //console.log('match', match);
                            const field = match[1];
                            const operator = match[2];
                            const value = match[3];
                            objectData.push({ field, operator, value });
                        }
                        // Reset the regular expression's lastIndex for the next iteration
                        pattern.lastIndex = 0;
                    }
                }

                //console.log('objectData', objectData);
                let queryfilter = objectData.map((value) => {
                    return `where ${value.field} ${value.operator} '${value.value}'`;
                });

                let querydata = '';
                if (objectData.length > 0) {
                    querydata = queryfilter.join(' AND ');
                }

                setfilterQuery(querydata);

                setFilterRows(objectData)
            }

        }

        // *************************************** fetch group by query ***********************************
        if (locationdata?.groupbyquery.query) {
            //console.log('if call query')

            update1.field = locationdata?.groupbyquery.field;
            update2.groupcolumn = locationdata?.groupbyquery.groupcolumn
            const queryResult = await SparkApi.fetchGroupbyQueryData(locationdata?.groupbyquery.query);
            //console.log('group by query result', queryResult)
            if (queryResult && queryResult.length > 0) {
                setgroupbyqueryresult(queryResult);
                //console.log('groupbyqueryresult ==> ', groupbyqueryresult)
            } else {
                setgroupbyqueryresult([]);
                //console.log('No data found');
            }
        }
        // ************************* Sorting Work *********************************************
        let orderby = locationdata?.query.split(' ORDER BY ');
        //console.log('order by ==>', orderby[1])
        let sortingdata = orderby[1].split(',');

        let result = [];
        sortingdata.forEach(item => {
            let parts = item.trim().split(' ');
            if (parts.length >= 0) {
                result.push({
                    sorting: parts[1].trim(),
                    sortingfield: parts[0].trim()
                });
            }
        });

        //console.log('result', result);
        setsortedRow(result);

    };




    // Used for field filter new row 
    const createFilterNewRow = (field, operator, value, index, data_type) => {
        // Check if the index corresponds to an existing row
        const existingRow = filterrows[index];
        if (existingRow) {
            // Update the existing row with the new values
            existingRow.field = field;
            existingRow.operator = operator;
            existingRow.value = value;
            existingRow.index = index;
            existingRow.data_type = data_type
            return existingRow; // Return a new array with the existing rows
        } else {
            // If the index does not correspond to an existing row, create a new row
            const newRow = {
                field: field,
                operator: operator,
                value: value,
                index: index, // Include the index property in the new row object
                data_type: data_type
            };
            return newRow; // Return a new array with the new row added
        }
    };


    // Access All fields Table wise
    const accessTableField = async () => {
        const fields = await SparkApi.fetchTableFields(locationdata?.tablename);
        if (fields) {
            setFieldData(fields);
        } else {
            setFieldData([]);
        }
    };

    // Tab keys 
    const handleSelect = (key) => {
        //console.log('key', key)
        setActiveTab(key);
    };

    // Nav tab
    const handleNavSelect = (key) => {
        setactivenavTab(key)
    };

    // Create table headers consisting of 4 columns.
    let header = rows.map((value, index, array) => ({
        title: value.fieldname,
        prop: value.fieldname,
        isFilterable: true,
    }));

    // Selected fields add in new row 
    const handlechangeSelectedFields = async (e) => {
        const newRow = createNewRow(e.target.value);
        setRows([...rows, newRow]);
        setSelectedColumn({
            ...selectedColumn,
            fieldname: newRow,
        });
        const updatedrows = [...rows, newRow]
        const fieldnames = updatedrows.map((row) => row.fieldname);
        const commaSeparated = fieldnames.join(", ");
        setcommaQuery(commaSeparated);

        let query = `select ${commaSeparated} FROM ibs_ibirds.${locationdata.tablename}`;
        setDatatableFields(query)

        let queryResult = await SparkApi.fetchQueryData(query);
        if (queryResult) {

            queryResult.map((value, index, array) => {
                value.createddate = moment(value.createddate).format('YYYY-MM-DD')
                value.lastmodifieddate = moment(value.createddate).format('YYYY-MM-DD')
            })
            setShowSortBy(true)
            setBody(queryResult);
            setFilter(true)
        } else {
            setBody([]);
        }
        setDataTableShow(true);
    };


    // Field selected in new row 
    const createNewRow = (selectedOption) => {
        const newRow = {
            fieldname: selectedOption,
        };
        return newRow;
    };

    // delete field row
    const handleDeleteRow = async (index) => {
        //console.log('filterQuery', filterQuery)
        //console.log('sortingQuery', sortingQuery)
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
        const fieldnames = updatedRows.map((row) => row.fieldname);
        const commaSeparated = fieldnames.join(", ");
        setcommaQuery(commaSeparated);
        let query = `select ${commaSeparated} FROM ibs_ibirds.${locationdata.tablename} ${filterQuery} ${sortingQuery}`;
        setDatatableFields(query)
        //console.log('query==>', query)
        let queryResult = await SparkApi.fetchQueryData(query);
        if (queryResult) {
            queryResult.map((value, index, array) => {
                value.createddate = moment(value.createddate).format('YYYY-MM-DD')
                value.lastmodifieddate = moment(value.createddate).format('YYYY-MM-DD')
            })
            setBody(queryResult);
            setFilter(true)
        } else {
            setBody([]);
        }
        setDataTableShow(true);

    };

    // field filter delete
    const handleFilterDeleteRow = async (index) => {
        const updatedRows = [...filterrows];
        updatedRows.splice(index, 1);
        setFilterRows(updatedRows);
        let filterQueryStr = ''
        if (updatedRows.length > 0) {
            filterQueryStr = updatedRows.map((filter, index) => {
                if (filter.data_type === 'timestamp without time zone') {
                    return index > 0 ? ` AND DATE(${filter.field}) ${filter.operator}'${filter.value}'` : `DATE(${filter.field}) ${filter.operator}'${filter.value}'`;
                } else {
                    return index > 0 ? ` AND ${filter.field} ${filter.operator}'${filter.value}'` : `${filter.field} ${filter.operator}'${filter.value}'`;
                }
            }).join(' ');
            filterQueryStr = `where ${filterQueryStr}`;
        }

        //console.log('filterQueryStr', filterQueryStr);
        setfilterQuery(filterQueryStr);
        let filterQuery = `SELECT ${commaQuery} FROM ibs_ibirds.${locationdata.tablename} ${filterQueryStr}`;
        //console.log('filterQuery', filterQuery)
        let filterQueryResult = await SparkApi.fetchFilterQueryData(filterQuery);
        //console.log('filterQueryResult==> ', filterQueryResult)
        if (filterQueryResult) {
            setBody(filterQueryResult);
        } else {
            setBody([]);
        }
        setDataTableShow(true);
        let query = ''
        if (
            update1.fieldtype === 'character varying' &&
            update2.groupcolumntype === 'character varying'
        ) {
            setintegerSummerizedata(false);

            query = `SELECT count(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterQueryStr ? `${filterQueryStr}` : ''} GROUP BY ${update2.groupcolumn} `;
            //console.log('query first condition ==> ', query);
            update3.groupdsummraize = 'count';
        } else if (
            (update1.fieldtype === 'integer' ||
                update1.fieldtype === 'numeric') &&
            update2.groupcolumntype === 'character varying'
        ) {
            query = `SELECT ${update3.groupdsummraize}(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterQueryStr ? `${filterQueryStr}` : ''} GROUP BY ${update2.groupcolumn}`;
            //console.log('query second condition ==> ', query);
        } else if (
            update1.fieldtype === 'character varying' &&
            (update2.groupcolumntype === 'integer' ||
                update2.groupcolumntype === 'numeric')
        ) {
            setintegerSummerizedata(true);
            query = `SELECT  ${update3.groupdsummraize}(${update2.groupcolumn}) AS count, ${update1.field} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterQueryStr ? `${filterQueryStr}` : ''} GROUP BY ${update1.field}`;
            //console.log('query third condition ==> ', query);

        } else {
            //console.log('Else Chala No Data Found');
            query = `SELECT count(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterQueryStr ? `${filterQueryStr}` : ''} GROUP BY ${update2.groupcolumn}`;
        }

        try {
            //console.log('query ==>', query)
            const updatedObject = { ...groupQueryObject, field: update1.field, fieldtype: update2.groupcolumn, groupcolumn: locationdata?.groupbyquery?.groupcolumn, groupcolumntype: locationdata?.groupbyquery?.groupcolumntype, groupdsummraize: update3.groupdsummraize, query: query }
            setgroupQueryObject(updatedObject)
            setgroupby(query)
            const queryResult = await SparkApi.fetchGroupbyQueryData(query);
            if (queryResult && queryResult.length > 0) {
                setgroupbyqueryresult(queryResult);
                //console.log('groupbyqueryresult ==> ', groupbyqueryresult)
            } else {
                setgroupbyqueryresult([]);
                //console.log('No data found');
            }
        } catch (error) {
            console.error('Error fetching query result:', error);
        }

    };

    // Run Report button click show datatable records 
    const handleFieldRunReport = async () => {
        const fieldnames = rows.map((row) => row.fieldname);
        const commaSeparated = fieldnames.join(", ");
        setcommaQuery(commaSeparated);
        let query = `select ${commaSeparated} FROM ibs_ibirds.${locationdata.tablename}`;
        setDatatableFields(query)
        let queryResult = await SparkApi.fetchQueryData(query);
        if (queryResult) {
            queryResult.map((value, index, array) => {
                value.createddate = moment(value.createddate).format('YYYY-MM-DD')
                value.lastmodifieddate = moment(value.createddate).format('YYYY-MM-DD')
            })
            setBody(queryResult);
            setFilter(true)
        } else {
            setBody([]);
        }
        setDataTableShow(true);
    };

    const handleReportnameModal = (e) => {
        const { name, value } = e.target;
        if (name === 'name') {
            const apiName = value ? value.replace(/\s/g, '') : '';
            setreportnamedata({ ...reportnamedata, [name]: value, apiname: apiName });
        } else {
            setreportnamedata({ ...reportnamedata, [name]: value });
        }
    };

    // Create report record
    const [charttype, setcharttype] = useState('');
    const handleSubmit = async (e) => {
        //console.log('filterQuery', filterQuery);
        let charttype = ''
        if (verticalchart === true) {
            charttype = 'vertical';
        } else if (barchart === true) {
            charttype = 'barchart';
        } else if (piechart === true) {
            charttype = 'piechart';
        }
        //console.log('current chart', charttype)
        setcharttype(charttype)
        e.preventDefault();
        let querydata = `${dataTableFields} ${filterQuery} ${sortingQuery}`;
        //console.log('querydata', querydata);
        //console.log('===>', groupby, groupQueryObject)
        let updatedData = { ...completeData, query: querydata, groupbyquery: groupQueryObject };
        //console.log('updateddata ===>', updatedData);
        updatedData.groupbyquery.query = groupby
        updatedData.charttype = charttype
        setcompleteData(updatedData);
        let id = locationdata.id;
        delete updatedData.id;
        //console.log('updatedData', updatedData)

        let updateResult = await SparkApi.savereport(id, updatedData);
        //console.log('updateResult', updateResult)
        PubSub.publish("RECORD_SAVED_TOAST", {
            title: "Record Saved",
            message: "Record saved successfully",
        });
        updatedData.iscustom = true
        updatedData.id = id
        updatedData.tablename = locationdata.tablename
        updatedData.name = locationdata.name
        setLocationData(updatedData)

    };


    // Chart Show hide 
    const handleChart = (chartname) => {
        if (chartname === 'Vertical Chart') {
            setbarchart(false)
            setpiechart(false)
            setverticalchart(true);
        } else if (chartname === 'Pie Chart') {
            setbarchart(false)
            setpiechart(true);
            setverticalchart(false)
        } else if (chartname === 'Bar Chart') {
            setbarchart(true)
            setpiechart(false);
            setverticalchart(false)
        }
    }


    const handleGroupbyFields = async (e) => {
        const { name, value } = e.target;
        const groupColumn = e.target.options[e.target.selectedIndex].getAttribute('groupcolumntype');

        const updatedGroupbyQuery = {
            ...groupbyQuery,
            [name]: value,
            groupcolumntype: groupColumn,
        };

        setupdate2(updatedGroupbyQuery);

        if (Object.keys(update1).length !== 0 && Object.keys(update3).length !== 0) {
            const generateFilterCondition = () => {
                const conditions = filterrows.map(({ field, operator, value }) => `${field} ${operator} '${value}'`);
                return conditions.join(' and ');
            };

            const filterCondition = generateFilterCondition();

            let query = '';
            const isFieldTypeChar = update1.fieldtype === 'character varying';
            const isGroupColumnTypeChar = updatedGroupbyQuery.groupcolumntype === 'character varying';
            const isFieldTypeNum = update1.fieldtype === 'integer' || update1.fieldtype === 'numeric';
            const isGroupColumnTypeNum = updatedGroupbyQuery.groupcolumntype === 'integer' || updatedGroupbyQuery.groupcolumntype === 'numeric';

            if (isFieldTypeChar && isGroupColumnTypeChar) {
                setintegerSummerizedata(false);
                query = `SELECT ${update3.groupdsummraize ? update3.groupdsummraize : 'count'}(${updatedGroupbyQuery.groupcolumn}) AS count, ${update1.field} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update1.field}`;
            } else if (isFieldTypeNum && isGroupColumnTypeChar) {
                query = `SELECT count(${update1.field}) AS count, ${updatedGroupbyQuery.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${updatedGroupbyQuery.groupcolumn}`;
            } else if (isFieldTypeChar && isGroupColumnTypeNum) {
                setintegerSummerizedata(true);
                query = `SELECT ${update3.groupdsummraize}(${updatedGroupbyQuery.groupcolumn}) AS count, ${update1.field} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update1.field}`;
            } else {
                query = `SELECT count(${update1.field}) AS label, ${updatedGroupbyQuery.groupcolumn} AS count FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${updatedGroupbyQuery.groupcolumn}`;
            }

            try {
                //console.log('try aaya =====> ', update1, updatedGroupbyQuery, update3)
                const updateddata = {
                    ...groupQueryObject, field: update1.field, fieldtype: update1.fieldtype, groupcolumn: updatedGroupbyQuery.groupcolumn, groupcolumntype: updatedGroupbyQuery.groupcolumntype, groupdsummraize: update3.groupdsummraize
                }
                setgroupQueryObject(updateddata);
                setgroupby(query)
                const queryResult = await SparkApi.fetchGroupbyQueryData(query);
                if (queryResult && queryResult.length > 0) {
                    setgroupbyqueryresult(queryResult);
                } else {
                    setgroupbyqueryresult([]);
                    //console.log('No data found');
                }
            } catch (error) {
                console.error('Error fetching query result:', error);
            }
        }
    };

    const handleFieldChange = async (e) => {
        const groupRow = e.target.options[e.target.selectedIndex].getAttribute('grouprow');

        const updatedGroupbyQuery = {
            ...groupbyQuery,
            [e.target.name]: e.target.value,
            fieldtype: groupRow,
        };

        delete updatedGroupbyQuery.groupcolumn;
        setupdate1(updatedGroupbyQuery);

        if (Object.keys(update2).length !== 0 && Object.keys(update3).length !== 0) {
            const generateFilterCondition = () => {
                const conditions = filterrows.map(({ field, operator, value }) => `${field} ${operator} '${value}'`);
                return conditions.join(' and ');
            };

            const filterCondition = generateFilterCondition();
            let query = '';

            const isFieldTypeChar = updatedGroupbyQuery.fieldtype === 'character varying';
            const isGroupColumnTypeChar = update2.groupcolumntype === 'character varying';
            const isFieldTypeNum = updatedGroupbyQuery.fieldtype === 'integer' || updatedGroupbyQuery.fieldtype === 'numeric';
            const isGroupColumnTypeNum = update2.groupcolumntype === 'integer' || update2.groupcolumntype === 'numeric';

            if (isFieldTypeChar && isGroupColumnTypeChar) {
                setintegerSummerizedata(false);
                query = `SELECT ${update3.groupdsummraize ? update3.groupdsummraize : 'count'}(${update2.groupcolumn}) AS count, ${updatedGroupbyQuery.field} AS label FROM ibs_ibirds.${locationdata.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${updatedGroupbyQuery.field}`;
            } else if (isFieldTypeNum && isGroupColumnTypeChar) {
                query = `SELECT ${update3.groupdsummraize}(${updatedGroupbyQuery.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update2.groupcolumn}`;
            } else if (isFieldTypeChar && isGroupColumnTypeNum) {
                setintegerSummerizedata(true);
                query = `SELECT ${update3.groupdsummraize}(${update2.groupcolumn}) AS count, ${updatedGroupbyQuery.field} AS label FROM ibs_ibirds.${locationdata.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update2.groupcolumn}`;
            } else {
                query = `SELECT count(${updatedGroupbyQuery.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update2.groupcolumn}`;
            }

            try {
                //console.log('try aaya =====> ', updatedGroupbyQuery, update2, update3)
                const updateddata = {
                    ...groupQueryObject, field: updatedGroupbyQuery.field, fieldtype: updatedGroupbyQuery.fieldtype, groupcolumn: update2.groupcolumn, groupcolumntype: update2.groupcolumntype, groupdsummraize: update3.groupdsummraize
                }
                setgroupQueryObject(updateddata)

                setgroupby(query)
                const queryResult = await SparkApi.fetchGroupbyQueryData(query);
                if (queryResult && queryResult.length > 0) {
                    setgroupbyqueryresult(queryResult);
                } else {
                    setgroupbyqueryresult([]);
                    //console.log('No data found');
                }
            } catch (error) {
                console.error('Error fetching query result:', error);
            }
        }
    };

    const handleSummurize = async (e) => {
        const updatedGroupbyQuery = {
            ...groupbyQuery,
            [e.target.name]: e.target.value,
        };
        setGroupbyQuery(updatedGroupbyQuery);
        //console.log(update1)
        //console.log(update2)
        //console.log(groupbyQuery)
        const generateFilterCondition = () => {
            const conditions = filterrows.map(({ field, operator, value }) => `${field} ${operator} '${value}'`);
            return conditions.join(' and ');
        };

        const filterCondition = generateFilterCondition();
        //console.log('filterCondition', filterCondition);

        let query = '';

        //console.log('fielddatatype ==> ', updatedGroupbyQuery.fielddatatype);
        //console.log('grouprowtype ==> ', updatedGroupbyQuery.grouprowtype);
        const updatedObject = { ...groupQueryObject, field: update1.field, fieldtype: update1.fieldtype, groupcolumn: update2.groupcolumn, groupcolumntype: update2.groupcolumntype, groupdsummraize: updatedGroupbyQuery.groupdsummraize }
        setgroupQueryObject(updatedObject);
        setupdate3(updatedGroupbyQuery)

        if (
            update1.fieldtype === 'character varying' &&
            update2.groupcolumntype === 'character varying'
        ) {
            setintegerSummerizedata(false);

            query = `SELECT count(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update2.groupcolumn} `;
            //console.log('query first condition ==> ', query);
            update3.groupdsummraize = 'count'
        } else if (
            (update1.fieldtype === 'integer' ||
                update1.fieldtype === 'numeric') &&
            update2.groupcolumntype === 'character varying'
        ) {
            query = `SELECT ${updatedGroupbyQuery.groupdsummraize}(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update2.groupcolumn}`;
            //console.log('query second condition ==> ', query);
        } else if (
            update1.fieldtype === 'character varying' &&
            (update2.groupcolumntype === 'integer' ||
                update2.groupcolumntype === 'numeric')
        ) {
            setintegerSummerizedata(true);
            query = `SELECT  ${updatedGroupbyQuery.groupdsummraize}(${update2.groupcolumn}) AS count, ${update1.field} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update1.field}`;
            //console.log('query third condition ==> ', query);

        } else {
            //console.log('Else Chala No Data Found');
            query = `SELECT ${updatedGroupbyQuery.groupdsummraize}(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${filterCondition ? `where ${filterCondition}` : ''} GROUP BY ${update2.groupcolumn}`;
        }

        try {
            //console.log('query', query)
            //console.log('try aaya =====> ', update1, update2, updatedGroupbyQuery)
            const updatedData = {
                ...groupQueryObject, field: update1.field, fieldtype: update1.fieldtype, groupcolumn: update2.groupcolumn, groupcolumntype: update2.groupcolumntype, groupdsummraize: updatedGroupbyQuery.groupdsummraize
            }
            setgroupQueryObject(updatedData)

            setgroupby(query)
            const queryResult = await SparkApi.fetchGroupbyQueryData(query);
            if (queryResult && queryResult.length > 0) {
                setgroupbyqueryresult(queryResult);
                //console.log('groupbyqueryresult ==> ', groupbyqueryresult)
            } else {
                setgroupbyqueryresult([]);
                //console.log('No data found');
            }
        } catch (error) {
            console.error('Error fetching query result:', error);
        }
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    function toTitleCase(str) {
        const titleCase = str
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
        return titleCase;
    }

    const labels = {
        beforeSelect: " ",
    };
    const [sortingvalue, setSortingvalue] = useState({});

    const handleSortingFields = async (e) => {
        if (e.target.name === 'sortingfield') {
            setstate(e.target.value);
            sortingvalue.sorting = '  '
        }

        if (e.target.name === 'sorting') {
            const newRow = createSortedRow(e.target.value, state);
            //console.log(newRow);
            setsortedRow([...sortedRow, newRow]);
            const updatedrows = [...sortedRow, newRow];
            //console.log('updatedrows', updatedrows);
            //console.log('data query', dataTableFields);
            let sortingFields = '';
            if (updatedrows.length >= 0) {
                const orderByClause = updatedrows.map((value) => `${value.sortingfield} ${value.sorting}`).join(', ');
                sortingFields += ` ORDER BY ${orderByClause}`;
            }
            //console.log('sorting fields', sortingFields);

            setsortingQuery(sortingFields);
            let query = `${dataTableFields} ${filterQuery} ${sortingFields}`
            //console.log('query', query)
            let queryResult = await SparkApi.fetchQueryData(query);
            if (queryResult) {

                queryResult.map((value, index, array) => {
                    value.createddate = moment(value.createddate).format('YYYY-MM-DD');
                    value.lastmodifieddate = moment(value.createddate).format('YYYY-MM-DD');
                });
                setBody(queryResult);
                setFilter(true);
            }
            setDataTableShow(true);
        }
    }


    // Field selected in new row 
    const createSortedRow = (sortingvalue, sortingfield) => {
        const newRow = {

            sorting: sortingvalue,
            sortingfield: sortingfield,

        };
        return newRow;
    };


    // delete field row
    const handleSortDeleteRow = async (index) => {
        const updatedRows = [...sortedRow];
        updatedRows.splice(index, 1);
        //console.log(updatedRows);

        setsortedRow(updatedRows);
        //console.log('updatedRows', updatedRows);

        let sortingFields = '';

        if (updatedRows.length > 0) {
            const orderByClause = updatedRows.map((value) => `${value.sortingfield} ${value.sorting}`).join(', ');
            sortingFields = ` ORDER BY ${orderByClause}`;
        }

        setsortingQuery(sortingFields);

        let query = `${dataTableFields} ${filterQuery} ${sortingFields}`;
        //console.log('query', query);

        let queryResult = await SparkApi.fetchQueryData(query);
        if (queryResult) {
            queryResult.map((value, index, array) => {
                value.createddate = moment(value.createddate).format('YYYY-MM-DD');
                value.lastmodifieddate = moment(value.createddate).format('YYYY-MM-DD');
            });
            setBody(queryResult);
            setFilter(true);
        }

        setDataTableShow(true);
    };




    const handleFilterSelectedFields = (event) => {
        //console.log('handle filter')
        if (modalValues) {
            setmodalValues('')
        }
        const selectedField = event.target.value;
        const selectedDataType = event.target.options[event.target.selectedIndex].getAttribute('datatype');
        //console.log('Selected field:', selectedField);
        //console.log('Selected data type:', selectedDataType);
        const updatedFieldDatatype = {
            ...fieldDatatype,
            field: selectedField,
            fieldtype: selectedDataType
        }
        //console.log(updatedFieldDatatype)
        if (updatedFieldDatatype) {
            //console.log('call')
            setfieldDatatype(updatedFieldDatatype)
            setFilterPopupShow(true)
        } else {
            setfieldDatatype({})
        }

    };


    const handlemodalOperatorValue = (e) => {
        //console.log('modalValues ===>', modalValues)
        if (modalValues) {
            //console.log('if call')
            const updatedModal = {
                ...modalValues,
                [e.target.name]: e.target.value,
                field: modalValues.field,
            }
            //console.log('updatedModal ==>', updatedModal)
            setmodalValues(updatedModal)
        } else {
            const updatedModal = {
                ...modalValues,
                [e.target.name]: e.target.value,
                field: fieldDatatype.field,
                fieldtype: fieldDatatype.fieldtype
            }
            //console.log('updatedModal ==>', updatedModal)
            setmodalValues(updatedModal)
        }

    }

    const handleFilterEdit = (row, index) => {
        //console.log(row, index)
        row.index = index
        setFilterPopupShow(true)
        //console.log('row', row)
        setmodalValues(row)
    };



    const handleModalApplyFilter = async () => {
        if (modalValues) {
            //console.log('modalValues apply click ', modalValues)
            setFilterPopupShow(false)
            let filterQueryStr = '';
            const newRow = createFilterNewRow(modalValues.field, modalValues.operator, modalValues.value, modalValues.index, modalValues.fieldtype);
            var updatedRows = [];
            if (newRow.index === undefined) {
                updatedRows = [...filterrows, newRow];
            } else {
                updatedRows = [...filterrows]; // Add the new row to the existing filterrows array
            }
            setFilterRows(updatedRows);
            //console.log('updated row ', updatedRows)
            if (updatedRows.length > 0) {
                filterQueryStr = updatedRows.map((filter, index) => {
                    if (filter.data_type === 'timestamp without time zone') {
                        return index > 0 ? ` AND DATE(${filter.field}) ${filter.operator}'${filter.value}'` : `DATE(${filter.field}) ${filter.operator}'${filter.value}'`;
                    } else {
                        return index > 0 ? ` AND ${filter.field} ${filter.operator}'${filter.value}'` : `${filter.field} ${filter.operator}'${filter.value}'`;
                    }
                }).join(' ');
            }

            //console.log('filterQueryStr', filterQueryStr);
            let queryWithWhereClause = `where ${filterQueryStr}`;
            setfilterQuery(queryWithWhereClause);
            let filterQuery = `SELECT ${commaQuery} FROM ibs_ibirds.${locationdata.tablename} where ${filterQueryStr}`;
            //console.log('filterQuery', filterQuery)
            let filterQueryResult = await SparkApi.fetchFilterQueryData(filterQuery);
            //console.log('filterQueryResult==> ', filterQueryResult)
            if (filterQueryResult) {
                setBody(filterQueryResult);
            } else {
                setBody([]);
            }
            setDataTableShow(true);
            if (Object.keys(update1).length != 0 && Object.keys(update2).length != 0 && Object.keys(update3).length != 0) {

                //console.log('chart filter call', update1, update2, update3)
                let query = ''
                if (
                    update1.fieldtype === 'character varying' &&
                    update2.groupcolumntype === 'character varying'
                ) {
                    setintegerSummerizedata(false);

                    query = `SELECT count(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${queryWithWhereClause ? `${queryWithWhereClause}` : ''} GROUP BY ${update2.groupcolumn} `;
                    //console.log('query first condition ==> ', query);
                    update3.groupdsummraize = 'count';
                } else if (
                    (update1.fieldtype === 'integer' ||
                        update1.fieldtype === 'numeric') &&
                    update2.groupcolumntype === 'character varying'
                ) {
                    query = `SELECT ${update3.groupdsummraize}(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${queryWithWhereClause ? `${queryWithWhereClause}` : ''} GROUP BY ${update2.groupcolumn}`;
                    //console.log('query second condition ==> ', query);
                } else if (
                    update1.fieldtype === 'character varying' &&
                    (update2.groupcolumntype === 'integer' ||
                        update2.groupcolumntype === 'numeric')
                ) {
                    setintegerSummerizedata(true);
                    query = `SELECT  ${update3.groupdsummraize}(${update2.groupcolumn}) AS count, ${update1.field} AS label FROM ibs_ibirds.${locationdata?.tablename} ${queryWithWhereClause ? `${queryWithWhereClause}` : ''} GROUP BY ${update1.field}`;
                    //console.log('query third condition ==> ', query);

                } else {
                    //console.log('Else Chala No Data Found');
                    query = `SELECT count(${update1.field}) AS count, ${update2.groupcolumn} AS label FROM ibs_ibirds.${locationdata?.tablename} ${queryWithWhereClause ? `${queryWithWhereClause}` : ''} GROUP BY ${update2.groupcolumn}`;
                }

                try {
                    //console.log('query ==>', query)
                    setgroupQueryObject({ ...groupQueryObject, field: update1.field, fieldtype: update1.fieldtype, groupcolumn: update2.groupcolumn, groupcolumntype: update2.groupcolumntype, groupdsummraize: update3.groupdsummraize })
                    setgroupby(query);
                    const queryResult = await SparkApi.fetchGroupbyQueryData(query);
                    if (queryResult && queryResult.length > 0) {
                        setgroupbyqueryresult(queryResult);
                        //console.log('groupbyqueryresult ==> ', groupbyqueryresult)
                    } else {
                        setgroupbyqueryresult([]);
                        //console.log('No data found');
                    }
                } catch (error) {
                    console.error('Error fetching query result:', error);
                }
            }
        }
    }
    const handleSaveAsSubmit = (e) => {
        //console.log('call')
        setreportnamemodal(true)
        e.preventDefault()
    }


    const handleCopySubmit = async (e) => {
        let charttype = ''
        if (verticalchart === true) {
            charttype = 'vertical';
        } else if (barchart === true) {
            charttype = 'barchart';
        } else if (piechart === true) {
            charttype = 'piechart';
        }
        //console.log('current chart', charttype)
        setcharttype(charttype)
        e.preventDefault()
        const name = reportnamedata.name;
        const apiname = reportnamedata.apiname;
        const description = reportnamedata.description;
        let querydata = `${dataTableFields} ${filterQuery} ${sortingQuery}`;
        //console.log('querydata', querydata);
        let updatedData = {
            ...completeData,
            name: name,
            apiname: apiname,
            description: description,
            query: querydata,
            groupbyquery: groupQueryObject,
            tablename: locationdata.tablename,
            charttype: charttype,
            iscustom: true

        };
        //console.log('updatedData ==>', updatedData)
        if (updatedData.apiname) {
            try {


                let createReportResult = await SparkApi.createReport(updatedData);
                PubSub.publish("RECORD_SAVED_TOAST", {
                    title: "Record Created",
                    message: "Record Created successfully",
                });
                navigate(`/reports`);
                setreportnamemodal(false);
            } catch (error) {
                console.error('Error creating report:', error);
            }
        }

    }


    const handleViewReport = () => {
        navigate(`/reports/${locationdata.id}`, { state: locationdata })
    }





    return (
        <>
            <Row className="my-3 mx-1">
                <Col lg={12}>

                    <Link className="nav-link my-2" to="/reports">
                        Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Reports</div>
                    </Link>
                    <nav className="navbar navbar-light" style={{ backgroundColor: "#fff" }}>
                        <Col lg={7}>
                            <h5 style={{ marginLeft: '10px' }}>Report Name: {locationdata.name}</h5>
                        </Col>
                        <Col lg={5} className="d-flex justify-content-end">

                            <Button variant="warning" onClick={handleViewReport}>
                                View Report
                            </Button>

                            <ButtonGroup style={{ backgroundColor: '#adb5bd' }} className="mx-2">
                                <Button variant="default" onClick={handleSubmit}>
                                    <i className="fa fa-floppy-o"></i> Save
                                </Button>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle split variant="default" >
                                        <i className="fa fa-caret-down"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu show={isDropdownOpen}>

                                        <Dropdown.Item onClick={handleSaveAsSubmit}>Save As</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonGroup>


                            {/* <Button variant="primary" className="mx-2" onClick={handleFieldRunReport}>
                                Run Report
                            </Button> */}
                        </Col>
                    </nav>
                </Col>
            </Row>

            <Row lg={12}>
                <Col lg={3} className="mx-1">
                    <Card bg="light" text="light" className="mb-2 ">
                        <Card.Header className="d-flex justify-content-between">
                            <Nav className="filter-section" variant="underline" defaultActiveKey="/Fields" activeKey={activeTab} onSelect={handleSelect} >
                                <Nav.Item>
                                    <Nav.Link eventKey="Fields" >Fields</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="Filter" >Filter</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="Sort By" >Sort By</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Card.Header>
                        <Card.Body>
                            {/* Render content based on the active tab */}
                            {activeTab === "Fields" && (
                                <div>
                                    <Form.Select onChange={handlechangeSelectedFields}>
                                        <option>Select</option>
                                        {fieldData.map((value, index, array) => (
                                            <option key={index} value={value.column_name}>
                                                {toTitleCase(value.column_name)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <div className="capitalFields">
                                        <Table className="my-3">
                                            <thead>
                                                <tr>
                                                    <th>Field Name</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows.map((row, index) => (
                                                    <tr key={index}>
                                                        <td style={{ whiteSpace: 'pre-wrap' }} className="capitalFields" >{row.fieldname}</td>
                                                        <td >
                                                            <Button variant="danger" style={{ marginLeft: "7px" }} size="sm" onClick={() => handleDeleteRow(index)}>
                                                                <i className="far fa-trash-alt"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            )}

                            {activeTab === "Filter" && (
                                <div>
                                    <Form.Select onChange={handleFilterSelectedFields} name="field">
                                        <option>Select</option>
                                        {fieldData.map((value, index) => (
                                            <option key={index} value={value.column_name} datatype={value.data_type}>
                                                {toTitleCase(value.column_name)}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    <div className="capitalFields">
                                        <Table className="my-3">
                                            <thead>
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filterrows.map((row, index) => (
                                                    <tr key={index}>
                                                        <td style={{ whiteSpace: 'pre-wrap' }}>
                                                            <Link
                                                                onClick={() => handleFilterEdit(row, index)}
                                                                onChange={() => handlemodalOperatorValue(row)}
                                                            >
                                                                {row.field}
                                                            </Link>
                                                        </td>
                                                        <td>{row.operator} {row.value}</td>

                                                        <td>
                                                            <Button
                                                                size="sm"
                                                                variant="danger"
                                                                onClick={() => handleFilterDeleteRow(index)}
                                                                style={{ marginLeft: "7px" }}
                                                            >
                                                                <i className="far fa-trash-alt"></i>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>





                                    {/* Modal */}

                                    <Modal size="sm" show={filterPopupShow} onHide={() => setFilterPopupShow(false)} aria-labelledby="example-modal-sizes-title-sm">
                                        <Modal.Header closeButton>
                                            <Modal.Title id="example-modal-sizes-title-sm">
                                                Filter by {fieldDatatype.field}
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form.Group controlId="filterForm">
                                                <Form.Label>Select an operator:</Form.Label>
                                                <Form.Select onChange={handlemodalOperatorValue} value={modalValues.operator} className="my-3" name="operator">
                                                    <option value="">Select</option>
                                                    <option value="=">equal</option>
                                                    <option value="!=">not equal to</option>
                                                    <option value="<">less than</option>
                                                    <option value=">">greater than</option>
                                                    <option value="<=">less or equal</option>
                                                    <option value=">=">greater or equal</option>
                                                    {/* <option value="contains">contains</option>
                                                    <option value="LIKE">does not contain</option>
                                                    <option value="LIKE">starts with</option> */}
                                                </Form.Select>
                                                <Form.Label>Enter a value</Form.Label>
                                                {fieldDatatype.fieldtype === 'timestamp without time zone' ?
                                                    <Form.Control
                                                        className="mb-3"
                                                        type="date"
                                                        onChange={handlemodalOperatorValue}
                                                        name="value"
                                                        value={modalValues.value}
                                                    />
                                                    : <Form.Control
                                                        className="mb-3"
                                                        type="text"
                                                        onChange={handlemodalOperatorValue}
                                                        name="value"
                                                        value={modalValues.value}
                                                    />}
                                            </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button onClick={() => { setFilterPopupShow(false) }} variant="danger">Cancel</Button>
                                            <Button onClick={handleModalApplyFilter} variant="primary">Apply</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            )}
                            {activeTab === 'Sort By' && (
                                <>
                                    <Row lg={12}>
                                        <Col lg={6}>
                                            <Form.Select onChange={handleSortingFields} name="sortingfield">
                                                <option>Select</option>
                                                {fieldData.map((value, index, array) => (
                                                    <option key={index} value={value.column_name}>
                                                        {value.column_name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Select onChange={handleSortingFields} name="sorting" value={sortingvalue.sorting}>
                                                <option>Select</option>
                                                <option value="ASC">ASC</option>
                                                <option value="DESC">DESC</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <div>

                                        <div>
                                            {sortedRow.map((row, index) => (
                                                <Alert key={index} variant="light" className="my-2 d-flex align-items-center justify-content-between">
                                                    <p className="my-2">{row.sortingfield}</p> = <p className="my-2">{row.sorting}</p>
                                                    <Button size="sm" variant="danger" onClick={() => handleSortDeleteRow(index)}><i className="fa fa-times"></i></Button>
                                                </Alert>
                                            ))}
                                        </div>

                                    </div>
                                </>

                            )}
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={7} style={{ width: '75%', marginLeft: '-17px' }}>
                    <Card bg="light" text="light" className="mb-2">
                        <Card.Header className="d-flex justify-content-between">
                            <Tabs
                                defaultActiveKey="Report"
                                id="uncontrolled-tab-example"
                                onSelect={handleNavSelect}
                            >
                                <Tab eventKey="Report" title="Report"></Tab>
                                {body.length > 0 ?
                                    <Tab eventKey="Chart" title="Chart"></Tab>
                                    : ''}
                            </Tabs>
                        </Card.Header>
                        <Card.Body>
                            {activenavTab === 'Report' ?

                                <DatatableWrapper
                                    body={body}
                                    headers={header}
                                    paginationOptionsProps={{
                                        initialState: {
                                            rowsPerPage: 10,
                                            options: [5, 10, 15, 20],
                                        },
                                    }}
                                >
                                    <Row className="mb-4">
                                        <Col
                                            xs={12}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Filter />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-start align-items-start"
                                        >
                                            {/* <PaginationOptions labels={labels} /> */}
                                        </Col>

                                    </Row>
                                    {datatableShow && (
                                        <Table striped className="data-table" responsive="sm">
                                            <TableHeader />
                                            <TableBody />
                                        </Table>
                                    )}
                                    <Pagination />
                                </DatatableWrapper>
                                : null}

                            {activenavTab === 'Chart' ? (
                                <>
                                    <Row className="justify-content-center mx-5">
                                        <Col lg={3} style={{ color: 'black' }}>
                                            Group Row
                                        </Col>
                                        <Col lg={3} style={{ color: 'black' }}>
                                            Group Column
                                        </Col>
                                        <Col lg={3} style={{ color: 'black' }}>
                                            Summarize
                                        </Col>
                                        <Col lg={3} style={{ color: 'black' }}>

                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center mx-5" >
                                        <Col lg={3}>
                                            <Form.Select name="field" onChange={handleFieldChange} required value={update1.field}>
                                                <option>Select</option>
                                                {fieldData.map((value, index) => (
                                                    <option key={index} value={value.column_name} grouprow={value.data_type}>
                                                        {toTitleCase(value.column_name)}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col lg={3}>
                                            <Form.Select name="groupcolumn" onChange={handleGroupbyFields} required value={update2.groupcolumn}>
                                                <option>Select</option>
                                                {fieldData.map((value, index) => (
                                                    <option key={index} value={value.column_name} groupcolumntype={value.data_type}>
                                                        {toTitleCase(value.column_name)}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col lg={3}>
                                            <Form.Select name="groupdsummraize" onChange={handleSummurize} required value={update3.groupdsummraize}>
                                                <option value="count">Count</option>
                                                <option value="MIN">MIN</option>
                                                <option value="SUM">SUM</option>
                                                <option value="MAX">MAX</option>
                                            </Form.Select>
                                        </Col>
                                        <Col lg={3} className="">
                                            <DropdownButton id="chart-dropdown" title="Chart Types">
                                                <Dropdown.Item onClick={() => handleChart('Bar Chart')}>
                                                    <FontAwesomeIcon icon={faChartColumn} /> Bar Chart
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleChart('Vertical Chart')}>
                                                    <FontAwesomeIcon icon={faChartBar} /> Vertical Chart
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleChart('Pie Chart')}>
                                                    <FontAwesomeIcon icon={faChartPie} /> Pie Chart
                                                </Dropdown.Item>
                                            </DropdownButton>
                                        </Col>
                                    </Row>
                                </>
                            ) : null}
                            {activenavTab === 'Chart' ?
                                <Col lg={12} className="text-center my-3">
                                    <Card className="" >
                                        <Card.Header >Group By REPORT</Card.Header>
                                        <Card.Body>
                                            <Card.Text>
                                                <div style={{ height: '350px' }}>
                                                    {barchart && (
                                                        <BarChart data={groupbyqueryresult} />
                                                    )}
                                                    {piechart && (
                                                        <PieChart data2={groupbyqueryresult} />
                                                    )}
                                                    {verticalchart && (
                                                        <VerticalChart data1={groupbyqueryresult} />
                                                    )}
                                                </div>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col> : ''}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={reportnamemodal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Report As</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Report Name</Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            onChange={handleReportnameModal}
                            name="name"
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Report Unique Name</Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            onChange={handleReportnameModal}
                            name="apiname"
                            value={reportnamedata?.apiname} // Add value prop to maintain controlled input
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Report Discription</Form.Label>
                        <Form.Control
                            className="mb-3"
                            as="textarea"
                            onChange={handleReportnameModal}
                            name="discription"
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleSubmit}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={reportnamemodal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Save Report As</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Report Name</Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            onChange={handleReportnameModal}
                            name="name"
                            value={reportnamedata.name}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Report Unique Name</Form.Label>
                        <Form.Control
                            className="mb-3"
                            type="text"
                            onChange={handleReportnameModal}
                            name="apiname"
                            value={reportnamedata.apiname}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Report Discription</Form.Label>
                        <Form.Control
                            className="mb-3"
                            as="textarea"
                            onChange={handleReportnameModal}
                            name="discription"
                            value={reportnamedata.discription}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>


                    <Button variant="primary" onClick={handleCopySubmit}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ReportEdit;
