import React, { useState, useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-bootstrap-typeahead/css/Typeahead.css";
import moment from "moment";
import SparkApi from "../api/SparkApi";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import { preventDefault } from "@fullcalendar/core/internal";

const PaymentEdit = (props) => {
    //console.log('props', props.payment)
    const [validated, setValidated] = useState(false);
    const [option, setOption] = useState();
    const [location, setLocation] = useState(useLocation());
    const navigate = useNavigate();
    const [payment, setPayment] = useState(props.payment ? props.payment.row : { parentid: props.parentid });

    const [show, setShow] = useState(true);
    const [selectedUser, setSelectedUser] = useState('');
    const [optionUsers, setOptionUsers] = useState([]);
    const [ownerList, setOwnerList] = useState([]);
    const [defaultOwner, setDefaultOwner] = useState([]);
    const [defaultTargetDate, setDefaultTargetDate] = useState(new Date());

    useEffect(() => {

        async function init() {

            //console.log('props.payment', props.payment.row.id)
            //const result = await SparkApi.fetchPaymentById(props.payment.row.id);
            ////console.log('fetchPaymentById',result)
            //  setPayment(result)



        }
        init();
    }, []);


    const handleChange = (e) => {
        setPayment({ ...payment, [e.target.name]: e.target.value, businessid: props.parent?.id, contactid: props.parent?.contactid });
    };

    //console.log('payment', payment)

    const checkRequiredFields = () => {
        if (typeof payment.paymentdate === 'undefined' || payment.paymentdate === null) {
          //console.log('if call');
          payment.paymentdate = new Date().toISOString();
        }
      
        if (payment.type && payment.amount && payment.discription) {
          return false;
        }
      
        return true;
      };
      
      


    const handleSubmit = async (e) => {
        if (checkRequiredFields()) {
            setValidated(true);
            return;
        }

        e.preventDefault()        

        
        if (payment.id) {
            //console.log('edit call');
            const result = await SparkApi.savePayment(payment);
            if (result) {
                submitpayments();
            }
        } else {
            const result = await SparkApi.createPayment(payment);
            //console.log('result', result);
            submitpayments();
            if (result) {
                //setShow(false)
            }
        }
    };

    const submitpayments = () => {
        props.submitpayments();
    };


    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {!payment.id ? 'Create Payment' : 'Edit Payment'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="view-form">
                    <Row>
                        <Col lg={12}>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} controlId="paymentEdit">
                                <Row lg={12}>

                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicTitle">
                                            <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                                                Payment Date
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="date"
                                                name="paymentdate"
                                                value={payment.paymentdate ? moment(payment.paymentdate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicType">
                                            <Form.Label className="form-view-label" htmlFor="formBasicType">
                                                Type
                                            </Form.Label>
                                            <Form.Select
                                                required
                                                aria-label="Enter Payment Status"
                                                name="type"
                                                value={payment.type}
                                                onChange={handleChange}
                                            >
                                                <option>--Select--</option>
                                                <option value="Card">Card</option>
                                                <option value="UPI">UPI</option>
                                                <option value="Cheque">Cheque</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Net Banking">Net Banking</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicPriority">
                                            <Form.Label className="form-view-label" htmlFor="formBasicPriority">
                                                Amount
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                name="amount"
                                                value={payment.amount}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                                Remarks
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                name="discription"
                                                value={payment.discription}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                                Created Date
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                name="createddate"
                                                value={moment(payment.createddate).format('DD-MM-YYYY')}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mx-3" controlId="formBasicStatus">
                                            <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                                                Last Modified Date
                                            </Form.Label>
                                            <Form.Control
                                                disabled
                                                type="text"
                                                name="lastmodifieddate"
                                                value= {moment(payment.lastmodifieddate).format('DD-MM-YYYY')}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}>Save</Button>
                <Button onClick={props.onHide} variant="light">Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentEdit;
