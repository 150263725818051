import React, { useState, useEffect } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import RelatedListFiles from "./RelatedListFiles";
import RealetedLeadComponent from "./RealetedLeadTest";
import FilesCreate from "./FilesCreate";
import RelatedContacts from "./RelatedContacts";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import RelatedListBusiness from "./RelatedListBusiness";
import helper from "./common/helper";

import * as constants from '../constants/CONSTANT';

const AccountView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const account = location.state;
  const [account, setAccount] = useState(location.state ? location.state : {});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [relatedListContacts, setRelatedListContact] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [reletedListBusiness, setRelatedListBusiness] = useState(false);
  const [refreshBusinessList, setRefreshBusinessList] = useState();

  useEffect(() => {
    fetchAccount();
  }, []);

  const fetchAccount = () => {
    // Coming from Email
    if (
      !account.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      account.id = location.pathname.split("/")[2];
    }
    
    //console.log('account:', account);
    async function initAccount() {
      let result = await SparkApi.fetchAccountById(account.id);
      if (result) {
        setAccount(result);
      } else {
        setAccount({});
      }
    }
    initAccount();
  };


  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };
  const deleteAccount = async () => {
    const result = await SparkApi.deleteAccount(account.id);
    if (result.success) navigate(`/accounts`);
  };

  const editAccount = () => {
    navigate(`/accounts/${account.id}/e`, { state: account });
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  const handleSelect = (key) => {
    if (key === "contacts") {
      setRelatedListContact(true);
      setRelatedListFiles(false);
      setReletedListTask(false);
      setRelatedListBusiness(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setRelatedListContact(false);
      setReletedListTask(false);
      setRelatedListBusiness(false);
    } else if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListContact(false);
      setRelatedListFiles(false);
      setRelatedListBusiness(false);
    }else if (key === "business") {
      setRelatedListBusiness(true);
      setReletedListTask(false);
      setRelatedListFiles(false);
      setRelatedListContact(false);
    }
  };
  return (
    <div>
      {account && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteAccount}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
           <Row className="view-form">
         
         <Col lg={11} className="pb-3 pt-2">
       <Link className="nav-link" to="/accounts">
         Home <i className="fa-solid fa-chevron-right"></i> <div style={{color: "#23468c", display : "inline"}}>Accounts</div>
       </Link>
     </Col>
     <Col></Col>
           </Row>
          <Row className="view-form">
            <Col></Col>
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Account
                  <h5>{account.name}</h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editAccount(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  {helper.checkPermission(constants.MODIFY_ALL) && <Button
                    className="btn-sm"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>}
                </Col>
              </Row>
              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  <label></label>
                  <span>{account.name}</span>
                </Col>

                <Col lg={6}>
                  <label>Phone</label>
                  <span>{account.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{account.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Website</label>
                  <span>{account.website}</span>
                </Col>
                <Col lg={6}>
                  <label>Street</label>
                  <span>{account.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>{account.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{account.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{account.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{account.country}</span>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="contacts"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="contacts" title="Contacts"></Tab>
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="business" title="Business"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={account}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
              <TaskEdit
              show={modalShowTask}
              onHide={() => setModalShowTask(false)}
              parentid={account.id}
              table="account"
              submitTasks={submitTasks}
              />
            )}
            </Card.Header>
            <Card.Body>
              {relatedListContacts && <RelatedContacts parent={account} />}
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={account}
                />
              )}
            {reletedListTask ? <RelatedListTask refreshTaskList={refreshTaskList} parent={account}/> : ""}

            {reletedListBusiness ? <RelatedListBusiness refreshTaskList={refreshBusinessList} parent={account} /> : ""}

            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};
export default AccountView;
