import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import PubSub from "pubsub-js";
import Select from "react-select";
import CityState from "../constants/CityState.json";
import * as constants from '../constants/CONSTANT';

const ContactEdit = (props) => {
  const [validated, setValidated] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionAccount, setOptionAccount] = useState({});
  const [name, setName] = useState("");
  const [state, setState] = useState({});
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [option, setoption] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  //const [contact, setContact] = useState(location.state);
  const [contact, setContact] = useState(location.state ? location.state : {});

  useEffect(() => {
    if (contact.id) {
      let temp = {}
      temp.value = contact.accountid;
      temp.label = contact.accountname;
      setoption(temp);
    }

    let st = [];
    CityState.map((item) => {
      //////console.log(item.state,item.id);
      var obj = {};
      obj.value = item.state;
      obj.label = item.state;
      //////console.log(" obj.label >"+ obj.label)
      st.push(obj);
    });
    setState(st);

    let fetchedAccounts = [];
    accounts.map((item) => {
      fetchedAccounts.push({
        label: item.firstname,
        population: 4780127,
        capital: "Montgomery",
        region: "South",
      });
    });
    setOptions(fetchedAccounts);
  }, [accounts]);

  useEffect(() => {
    async function init() {
      const accounttData = await SparkApi.fetchAccounts();
      ////console.log("accounttData" + JSON.stringify(accounttData));
      let acc = [];
      accounttData.map((item) => {
        var obj = {};
        obj.value = item.id;
        obj.label = item.name;
        ////console.log(" obj.label >" + obj.label);
        acc.push(obj);
      });
      setOptionAccount(acc);
    }

    init();
  }, []);
  const handleAccount = (e) => {
    ////console.log('e', e)
    setoption(e);
    setContact({ ...contact, accountid: e.value, accountname: e.label });
  };
  const handleState = (e) => {
    let filteredCities = [];
    CityState.forEach(function (obj) {
      if (obj.state === e.value) {
        filteredCities.push({
          label: obj.name,
          value: obj.name,
        });
      }
    });
    setCities(filteredCities);
    setContact({ ...contact, state: e.value });
  };

  const handleSelectListChange = (value, name) => {
    ////console.log("contact:", contact);
    setContact({ ...contact, [name]: value.value });
    setSelectedCity(value.value);
  };

  {
    contact.id !== null && name === "" ? (
      setName(
        contact.firstname + " " + contact.lastname
      )
    ) : (
      <h1></h1>
    );
  }
  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const checkRequredFields = () => {
    if (
      contact.firstname &&
      contact.firstname.trim() !== "" &&
      contact.lastname &&
      contact.lastname.trim() !== "" &&
      contact.phone &&
      contact.phone.trim() !== ""
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkRequredFields()) {
      setValidated(true);
      return;
    }

    //========= Logic to perform Create or Edit ======
    let result = {};
    if (contact.id) {
      result = await SparkApi.saveContact(contact);
      if (result.success) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${contact.id}`, { state: contact });
      }
    } else {
      result = await SparkApi.createContact(contact);
      if (result) {
        PubSub.publish("RECORD_SAVED_TOAST", {
          title: "Record Saved",
          message: "Record saved successfully",
        });
        navigate(`/contacts/${result.id}`, { state: result });
      }
    }
  };

  const handleCancel = () => {
    if (contact.id) {
      navigate("/contacts/" + contact.id, { state: contact });
    } else {
      navigate(`/contacts`)
    }

  };
  return (
    <Container className="view-form">
      <Row >
            <Col></Col>
         <Col lg={8} className="pb-1 pt-2">
       <Link className="nav-link" to="/contacts">
         Home <i className="fa-solid fa-chevron-right"></i> <div style={{color: "#23468c", display : "inline"}}>Contacts</div>
       </Link>
     </Col>
     <Col></Col>
           </Row>
      <Row>
        <Col></Col>
        <Col lg={8}>
          <Form
            className="mt-3"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
          >
            
            <Row className="view-form-header align-items-center">
              <Col lg={8}>
                {contact.id ? (
                  <h5>Edit Contact {contact.firstname}</h5>
                ) : (
                  <h5>Create Contact</h5>
                )}


              </Col>
              <Col lg={4} className="d-flex justify-content-end">
                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                  Save
                </Button>
                <Button
                  className="btn-sm"
                  variant="danger"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Row className="ibs-edit-form pb-3 ">
              <Row className="mb-3">
                <Col lg={6}>
                  <Form.Group className="mx-3" >
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      Account Name
                    </Form.Label>
                    <Select

                      placeholder="Account Name... "
                      value={option}
                      onChange={handleAccount}
                      options={optionAccount}
                    //value={salutationValue}
                    ></Select>
                    <Form.Control.Feedback type="invalid">
                      Please provide Account Name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicTitle">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicTitle"
                    >
                      Title
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="title"
                      placeholder="Enter title"
                      value={contact.title}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row lg={12}>
                <Col lg={6} className="d-flex ">
                  <Form.Group className="mx-2" controlId="formBasicsalutation">
                    <Form.Label className="form-view-label" htmlFor="formBasicsalutation">
                      Salutation
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      as="select"
                      name="salutation"
                      value={contact.salutation}
                      onChange={handleChange}
                    >
                      <option value="">---Select---</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms.">Ms.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof.">Prof.</option>

                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formBasicFirstName">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicFirstName"
                    >
                      First Name
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px",width : "218px" }}
                      required
                      type="text"
                      name="firstname"
                      placeholder="Enter firstname"
                      value={contact.firstname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group controlId="formBasicLastName">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicLastName"
                      style={{ marginLeft: '10px' }}
                    >
                      Last Name
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px", marginLeft: '10px',width : '100%' }}
                      required
                      type="text"
                      name="lastname"
                      placeholder="Enter lastname"
                      value={contact.lastname}
                      onChange={handleChange}
                    />
                  </Form.Group>

                </Col>


              </Row>

              <Row>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicEmail">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicEmail"
                    >
                      Email
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      value={contact.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mx-3" controlId="formBasicPhone">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPhone"
                    >
                      Phone
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      required
                      type="phone"
                      name="phone"
                      placeholder="Enter phone"
                      value={contact.phone}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicState">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicState"
                    >
                      State
                    </Form.Label>
                    <Select
                      placeholder="State"
                      defaultValue={{
                        label: contact.state,
                        value: contact.state,
                      }}
                      onChange={handleState}
                      options={state}
                    //value={salutationValue}
                    ></Select>
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCity">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCity"
                    >
                      City
                    </Form.Label>
                    <Select
                      options={cities}
                      onChange={(e) => {
                        handleSelectListChange(e, "city");
                      }}
                      name="city"
                      defaultValue={{ label: contact.city, value: contact.city }}
                      placeholder="Enter City"
                    />
                  </Form.Group>
                </Col>


                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicPin">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicPin"
                    >
                      Pincode
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="pincode"
                      placeholder="Enter pincode"
                      value={contact.pincode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicCountry">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicCountry"
                    >
                      Country
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="country"
                      placeholder="Enter country"
                      value={contact.country}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mx-3" controlId="formBasicStreet">
                    <Form.Label
                      className="form-view-label"
                      htmlFor="formBasicStreet"
                    >
                      Street
                    </Form.Label>
                    <Form.Control
                      style={{ height: "36px" }}
                      type="text"
                      name="street"
                      placeholder="Enter Street"
                      value={contact.street}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/*<Row className="justify-content-md-center">
                            <Col lg={8}>
                                <Button  className="mx-3" variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Col>
                </Row>*/}
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Container >
  );
};

export default ContactEdit;
