import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import { Card, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import { CSVLink } from "react-csv";
import csvDownload from 'json-to-csv-export'

import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import BarChart from "./charts/BarChart";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ReportPDF from "./ReportPDF";
import VerticalChart from "./charts/VerticalChart";
import PieChart from "./charts/PieChart";

const ReportView = () => {
  const [arrName, setArrName] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const report = location.state;
  const [body, setBody] = useState([]);
  const [state, setState] = useState(location.state ? location.state : {});
  //console.log('state', state)
  const [activeTab, setActiveTab] = useState("Report");

  useEffect(() => {
    fetchDataById()


  }, []);
  let tempLabels
  const fetchDataById = async () => {
    if (location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3) {
      state.id = location.pathname.split('/')[2];
    }
    //console.log('id', state.id)
    let queryResult = await SparkApi.fetchReportsById(state.id);

    setState(queryResult[0])
    //console.log(' id call', queryResult)
    accessRowData(queryResult[0].query)
    groupRowData(queryResult[0].groupbyquery.query)

  }

  const accessRowData = async (query) => {
    //console.log('call', query)
    let queryResult = await SparkApi.fetchQueryData(query);
    //console.log('queryResult', queryResult);

    if (queryResult) {
      tempLabels = Object.keys(queryResult[0]);
      setBody(queryResult);
    } else {
      setBody([]);
    }
  };

  const [groupbyqueryresult, setgroupbyqueryresult] = useState([]);

  const groupRowData = async (groupquery) => {
    if (groupquery) {
      //console.log('groupquery', groupquery)
      let queryResult = await SparkApi.fetchGroupbyQueryData(groupquery);
      //console.log('queryResult', queryResult)
      //console.log('group qury result', queryResult)
      if (queryResult) {
        setgroupbyqueryresult(queryResult)
      } else {
        setgroupbyqueryresult([])
      }
    }
  }

  let header = Object.keys(body[0] || {}).map((value) => ({
    title: value,
    prop: value,
    isFilterable: true,
  }));

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const ReportDataCSV = {
    data: body,
    filename: state.name,
    delimiter: ',',
    headers: tempLabels
  }

  const pdfRecorder = () => {
    const pdfTable = document.getElementById("divToPrint");
    var html = htmlToPdfmake(pdfTable.innerHTML, {
      tableAutoSize: true,
    });
    const documentDefinition = {
      content: [html],
      pageBreakBefore: function (currentNode) {
        return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
      }
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).download();

  };

  const editReport = () => {

    navigate(`/reportedit/${state.id}`, { state: state })
  };


  return (
    <Row className="g-0">
      <Row className="my-1">
        <Col lg={12}>
          <Link className="nav-link my-3" to="/reports">
            Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Reports</div>
          </Link>
          <nav className="navbar navbar-light" style={{ backgroundColor: "#fff" }}>
            <Col lg={10}>
              <h5 style={{ marginLeft: '10px' }}>Report Name : <Badge>{state.name}</Badge></h5>
            </Col>
            <Col className="d-flex mx-4 ">
              {state.iscustom && (
                <Button className='btn-sm mx-2' variant="danger" onClick={editReport}>
                  <i className="far fa-pen-to-square"></i>
                </Button>
              )}
              <Dropdown style={{ backgroundColor: '#82878f' }}>
                <Dropdown.Toggle>
                  Download  <i class="fa fa-caret-down" aria-hidden="true"></i>

                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => csvDownload(ReportDataCSV)}>
                    <i class="fa-solid fa-file-csv" style={{ color: 'green' }}></i> CSV
                  </Dropdown.Item>
                  <Dropdown.Item onClick={pdfRecorder}>
                    <i className="far fa-file-pdf" style={{ color: 'red' }}></i> PDF
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>

          </nav>
        </Col>
      </Row>

      <Row lg={12}>
        <Col lg={12} className="my-3">
          <Card bg="light" text="light" >
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="Report"
                id="uncontrolled-tab-example"
                onSelect={handleSelect}
              >
                <Tab eventKey="Report" title="Report" />
                {state.iscustom && <Tab eventKey="Chart" title="Chart" />}
              </Tabs>
            </Card.Header>
            <Card.Body>
              {activeTab === "Report" && body ? (
                <Col lg={12} >
                  {/* Data Table */}
                  <DatatableWrapper
                    body={body}
                    headers={header}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 20,
                      },
                    }}
                  >
                    <Row className="">
                    </Row>
                    <Table striped className="data-table" responsive="sm">
                      <TableHeader />
                      <TableBody />
                    </Table>
                    <Pagination />
                  </DatatableWrapper>
                </Col>
              ) : null}
              {activeTab === "Chart" && (
                <Col lg={12} className="text-center my-3">
                  {/* Chart */}
                  <Card>
                    <Card.Header>Group By REPORT</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div style={{ height: "350px", width: '600px', marginLeft: '20%' }} >
                          {state.charttype === 'barchart' && <BarChart data={groupbyqueryresult} />}
                          {state.charttype === 'vertical' && <VerticalChart data1={groupbyqueryresult} />}
                          {state.charttype === 'piechart' && <PieChart data2={groupbyqueryresult} />}
                        </div>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="App container mt-5" style={{ display: 'none' }}>
        <div id="divToPrint">
          <ReportPDF header={header} data={body} reportname={state.name} />
        </div>
      </div>

      <Col lg={2}></Col>
    </Row>
  );
};
export default ReportView;