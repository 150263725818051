import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import moment from "moment";
import * as constants from "../constants/CONSTANT";
import PubSub from "pubsub-js";
import EmailComposer from "./common/EmailComposer";
import Chat from "./common/Chat";
import jwt_decode from "jwt-decode";

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [lead, setLead] = useState(location.state ? location.state : {});
  ////console.log('lead', lead)
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);

  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [convertShow, setConvertShow] = useState(true);


  const [messages, setMessages] = useState([
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this."
    },
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this."
    },
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this."
    }
  ])
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem('token')));
    fetchLead();


    //init();
  }, []);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      let result = await SparkApi.fetchLeadById(lead.id);

      if (
        result?.convertedcontactid &&
        result?.contactname &&
        result.contactname.trim().length > 0
      ) {
        ////console.log('convert call');
        setConvertShow(false);
      }
      if (result) {
        ////console.log("=======", result);

        setLead(result);
        setData(result);
        ////console.log("name : ", lead.firstname);
      } else {
        setLead({});
      }
    }
    initStudent();
  };
  const deleteLead = async () => {
    const result = await SparkApi.deleteLead(lead.id);
    if (result.success) navigate(`/leads`);
    ////console.log("result.success : ", result.success);
  };
  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  const handleConvert = async () => {
    let convertStatus = "";

    let status = leadStatusArray.filter((item) => item.is_converted === true);
    //console.log("status:", status);
    convertStatus = status[0].label;
    const result = await SparkApi.convertLeadToContact(lead.id, convertStatus);
    ////console.log('result', result)
    if (result) {
      let contactId = result.convertedcontactid;
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Lead Convert ",
        message: "Record Convert successfully",
      });
      navigate(`/contacts/${contactId}`);
      ////console.log("result.success : ", result.success);
    }
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
  };



  return (
    <Container>
      {lead && (
        <>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteLead}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/leads">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>

          <Row className="view-form gx-5 mx-auto">
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Lead
                  <h5>
                    {(lead.salutation ? lead.salutation : "")

                      + " " + lead.firstname +
                      " " +
                      lead.lastname}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editLead(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                    title="Send Email"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                  </Button>
                  {convertShow === true ? (
                    <Button
                      className="btn-sm mx-2"
                      variant="warning"
                      onClick={handleConvert}
                    >
                      Convert
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  <Path
                    values={leadStatusArray}
                    selectedValue={lead.leadstatus}
                  />
                </Col>

                <Col lg={6}>
                  <label>Lead Name</label>
                  <span>
                    {(lead.salutation ? lead.salutation : "")

                      + " " + lead.firstname +
                      " " +
                      lead.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{lead.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{lead.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Company</label>
                  <span>{lead.company}</span>
                </Col>
                <Col lg={6}>
                  <label>Title</label>
                  <span>{lead.title}</span>
                </Col>
                <Col lg={6}>
                  <label>Fax</label>
                  <span>{lead.fax}</span>
                </Col>
                <Col lg={6}>
                  <label>Lead Source</label>
                  <span>{lead.leadsource}</span>
                </Col>

                <Col lg={6}>
                  <label>User Name</label>
                  <span>{userName}</span>
                </Col>

                <Col lg={6}>
                  <label>Industry</label>
                  <span>{lead.industry}</span>
                </Col>

                <Col lg={6}>
                  <label>Expected Amount</label>
                  <span>₹ {lead.amount}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Model</label>
                  <span>{lead.paymentmodel}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Terms</label>
                  <span>{lead.paymentterms}</span>
                </Col>
                <Col lg={6}>
                  <label>Status</label>
                  <span>{lead.leadstatus}</span>
                </Col>
                {lead.iswon === true && (
                  <Col lg={6}>
                    <label>Converted Contact</label>
                    <span>
                      <Link to={"/contacts/" + lead.convertedcontactid}>
                        {lead.contactname}
                      </Link>
                    </span>
                  </Col>
                )}
                {lead.iswon === false && (
                  <Col lg={6}>
                    <label>Lost Reason</label>
                    <span>{lead.lostreason}</span>
                  </Col>
                )}
                <Col lg={6}>
                  <label>Assigned User</label>
                  <span>
                    <img src={`https://spark.indicrm.io/images/${userInfo.tenantcode}/users/${lead.ownerid}.thumbnail/${Math.random()}`} className="rounded-circle " style={{ height: "20px", width: "20px", objectFit: "cover" }} />
                    <Badge
                      bg="warning"
                      className="mx-2"
                      style={{ display: "inline", color: "#000" }}
                    >
                      {lead.ownername}
                    </Badge>
                  </span>
                </Col>
                <Col lg={12}>
                  <label>Description</label>
                  <span>{lead.description}</span>
                </Col>
                <Col lg={12}>
                  <label>Created Date</label>
                  <span>{moment(lead.createddate).format("DD MMM, yyyy")}</span>
                </Col>

                <Col lg={12} className="section-header">
                  ADDRESS INFORMATION
                </Col>
                <Col lg={6}>
                  <label>Address Street</label>
                  <span>{lead.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>
                    {lead.city !== null ? lead.city : ""} {lead.zipcode}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{lead.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{lead.country}</span>
                </Col>

                <Col className="mt-5"></Col>
              </Row>
            </Col>
            <Col lg={4}>

              <Chat parentid={lead.id} />


            </Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={lead}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <div>
                  <Button
                    className="float-right btn-sm mx-2"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Task
                  </Button>

                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button>
                </div>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={lead?.id}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={lead}
                />
              )}
              {reletedListTask ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={lead}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>

          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={lead?.id}
            toEmail={lead?.email}
            table="lead"
            submitTasks={submitTasks}
          />
        </>
      )}
    </Container>
  );
};
export default LeadView;
